export default [
  "Life is painting a picture, not doing a sum.", //  生活是绘画，不是做算术。
  "The wealth of the mind is the only wealth.", //  精神的财富是唯一的财富。
  "You cant judge a tree by its bark.", //  人不可貌相。
  "Sharp tools make good work.", //  工欲善其事，必先利其器。
  "Wasting time is robbing oneself.", //  浪费时间就是掠夺自己。
  "Nurture passes nature.", //  教养胜过天性。
  "There is no garden without its weeds.", //  没有不长杂草的花园。
  "A man is only as good as what he loves.", //  一个人要用他所爱的东西有多好来衡量。
  "Wealth is the test of a mans character.", //  财富是对一个人品格的试金石。
  "The best hearts are always the bravest.", //  心灵最高尚的人，也总是最勇敢的人。
  "One never lose anything by politeness.", //  讲礼貌不吃亏。
  "Suffering is the most powerful teacher of life.", //  苦难是人生最伟大的老师。
  "A bosom friend afar brings a distant land near.", //  海内存知己，天涯若比邻。
  "A common danger causes common action.", //  同舟共济。
  "A contented mind is a continual / perpetual feast.", //  知足常乐。
  "A fall into the pit, a gain in your wit.", //  吃一堑，长一智。
  "A guest should suit the convenience of the host.", //  客随主便。
  "A letter from home is a priceless treasure.", //  家书抵万金。
  "All rivers run into the sea.", //  殊途同归。
  "All time is no time when it is past.", //  机不可失，时不再来。
  "An apple a day keeps the doctor away.", //  一日一个苹果，身体健康不求医。
  "As heroes think, so thought Bruce.", //  英雄所见略同。
  "A young idler, an old beggar.", //  少壮不努力，老大徒伤悲。
  "Behind the mountains there are people to be found.", //  天外有天，山外有山。
  "Bad luck often brings good luck.", //  塞翁失马，安知非福。
  "Business is business.", //  公事公办。
  "Clumsy birds have to start flying early.", //  笨鸟先飞。
  "Do one thing at a time, and do well.", //  一次只做一件事，做到最好!
  "Sow nothing, reap nothing.", //  春不播，秋不收。
  "Life is real, life is earnest.", //  人生真实，人生诚挚。
  "Gossiping and lying go together.", //  流言常和谎话并行。
  "To be both a speaker of words and a doer of deeds.", //  既当演说家，又做实干家。
  "Variety is the spice of life.", //  变化是生活的调味品。
  "Bad times make a good man.", //  艰难困苦出能人。
  "There is no royal road to learning.", //  求知无坦途。
  "Doubt is the key to knowledge.", //  怀疑是知识的钥匙。
  "A mans best friends are his ten fingers.", //  人最好的朋友是自己的十个手指。
  "Only they who fulfill their duties in everyday matters will fulfill them on great occasions.", //  只有在日常生活中尽责的人才会在重大时刻尽责。
  "The shortest way to do many things is to only one thing at a time.", //  做许多事情的捷径就是一次只做一件事。
  "Life would be too smooth if it had no rubs in it.", //  生活若无波折险阻，就会过于平淡无奇。
  "Life is the art of drawing sufficient conclusions form insufficient premises.", //  生活是一种艺术，要在不充足的前提下得出充足的结论。
  "Life is fine and enjoyable, yet you must learn to enjoy your fine life.", //  人生是美好的，但要学会如何享用美好的生活。
  "Life is but a hard and tortuous journey.", //  人生即是一段艰难曲折的旅程，人生无坦途。
  "Life is a horse, and either you ride it or it rides you.", //  人生像一匹马，你不驾驭它, 它便驾驭你。
  "Life is a great big canvas, and you should throw all the paint on it you can.", //  人生是一幅大画布，你应该努力绘出绚丽多彩的画面。
  "Life is like music. It must be composed by ear, feeling and instinct, not by rule.", //  人生如一首乐曲，要用乐感，感情和直觉去谱写，不能只按乐律行事。
  "Theres only one corner of the universe you can be sure of improving, and thats your own self.", //  这个宇宙中只有一个角落你肯定可以改进，那就是你自己。
  "The world is like a mirror: Frown at itand it frowns at you; smile, and it smiles too.", //  世界犹如一面镜子: 朝它皱眉它就朝你皱眉，朝它微笑它也吵你微笑。
  "Death comes to all, but great achievements raise a monument which shall endure until the sun grows old.", //  死亡无人能免，但非凡的成就会树起一座纪念碑，它将一直立到太阳冷却之时。
  "The reason why a great man is great is that he resolves to be a great man.", //  伟人之所以伟大，是因为他立志要成为伟大的人
];
