<template>
  <div>
    <div class="header-placeholder"></div>
    <div class="header">
      <div class="left">
        <i class="snes-logo pointer" @click="goHome"></i>
        <div class="home pointer" @click="goHome">
          <template v-if="$route.name === 'home'">
            <div>Home</div>
            <div class="red animate-pulse"></div>
          </template>
          <template v-else>Home</template>
        </div>
        <div class="item pointer" @click="goPost">
          <template v-if="$route.name === 'posts'">
            <div>Post</div>
            <div class="red animate-pulse"></div>
          </template>
          <template v-else>Post</template>
        </div>
        <div class="item pointer" @click="goTool">
          <template v-if="$route.name === 'tools'">
            <div>Tools</div>
            <div class="red animate-pulse"></div>
          </template>
          <template v-else>Tools</template>
        </div>
      </div>
      <div class="right">
        <div class="item pointer" @click="goAbout">
          <template v-if="$route.name === 'about'">
            <div>About</div>
            <div class="red animate-pulse"></div>
          </template>
          <template v-else>About</template>
        </div>
        <div class="item pointer" @click="goGit">GitHub</div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'nav-bar',
    data() {
      return {
      }
    },
    computed: {

    },
    methods: {
      routeName() {
        return this.$route.name;
      },
      goHome() {
        if (this.$route.path !== '/') this.$router.push('/')
      },
      // 跳转关于页面
      goAbout() {
        if (this.$route.name !== 'about')this.$router.push({name: 'about'})
      },
      // 进入文章列表页
      goPost(){
        if (this.$route.name !== 'posts')this.$router.push({name: 'posts'})
      },
      // 跳转工具页面
      goTool(){
        if (this.$route.name !== 'tools')this.$router.push({name: 'tools'})
      },
      // 跳转git
      goGit() {
        window.open('https://github.com/liqunx/birdFly', '_blank');
      },

      nextDate() {
        if (this.date >= 31) {
          this.date = 0;
        }
        this.date++;
      }
    }
  } 
</script>
<style lang="less" scoped>
.header-placeholder, .header {
  height: 80px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  border-bottom: 4px solid #D3D3D3;
  background: white;
  .left {
    display: flex;
    align-items: center;
    .snes-logo {
      font-size: 36px;
      margin-right: 15px;
      margin-top:-10px;
    }
    .home {
      margin-right: 40px;
    }
  }
  .right {
    display: flex;
    align-items: center;
  }
  .left, .right{
    .item {
      font-size: 14px;
      margin-right: 20px;
    }
    .home, .item {
      height: 48px;
      position: relative;
      display: flex;
      align-items: center;
      .red {
        width: 100%;
        height: 4px;
        background:#ce372b;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}
</style>