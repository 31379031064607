<template>
  <div>
    <div class="footer-placeholder"></div>
    <div class="footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">网站备案号： 粤ICP备17062001号-1</a>
      <span>CopyRight @ Xuliqun</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'foot',
}
</script>
<style lang="less" scoped>
 .footer-placeholder, .footer {
    height: 50px;
  }
  .footer {
    position: fixed;
    width: 100vw;
    background: white;
    border-top: 4px solid #D3D3D3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #898989;
    font-family: 黑体;
    bottom: 0;
    left: 0;
    z-index: 9;
    a {
      color: #898989;
      margin-right: 30px;
    }
  }
</style>